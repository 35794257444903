<template>
  <div class="content-wrapper">
    <div class="content-body">
      <div class="row">
        <div class="col-12">
          <div data-v-5cb60a6f="" class="card"><!----><!---->
            <div data-v-5cb60a6f="" class="m-2">
              <div data-v-5cb60a6f="" class="row">
                <div class="col-3">
                  <router-link class="btn btn-primary" :to="{ path: `/apps/product/${this.$route.params.id}/attribute`}"> Add Attribute</router-link>
                </div>
              </div>
            </div>
            <h1 class="m-2">Attributes</h1>
            <div data-v-5cb60a6f="" class="position-relative table-responsive">
              <table
                  id="__BVID__1070"
                  role="table"
                  aria-busy="false"
                  aria-colcount="7"
                  class="table b-table"
              ><!---->
                <!---->
                <thead
                    role="rowgroup"
                    class=""
                ><!---->
                <tr
                    role="row"
                    class=""
                >
                  <th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="1"
                      aria-sort="descending"
                      class=""
                  >
                    <div>#</div>
                  </th><th
                    role="columnheader"
                    scope="col"
                    tabindex="0"
                    aria-colindex="3"
                    aria-sort="none"
                    class=""
                >
                  <div>Name</div>
                </th>
                  <th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="3"
                      aria-sort="none"
                      class=""
                  >
                    <div>Price</div>
                  </th>
                </tr>
                </thead>
                <tbody role="rowgroup"><!---->
                <tr
                    id="__BVID__1070__row_5036"
                    role="row"
                    data-pk="5036"
                    class=""
                    v-for="(item,index) in getterListAttribute"
                >
                  <td
                      aria-colindex="1"
                      role="cell"
                      class=""
                  ><a
                      data-v-5cb60a6f=""
                      href="/apps/invoice/preview/5036"
                      class="font-weight-bold"
                      target="_self"
                  > {{ index + 1 }} </a></td>
                  <td
                      aria-colindex="3"
                      role="cell"
                      class=""
                  >
                    <div
                        data-v-5cb60a6f=""
                        class="media"
                    >
                      <div
                          data-v-5cb60a6f=""
                          class="media-body"
                      ><span
                          data-v-5cb60a6f=""
                          class="font-weight-bold d-block text-nowrap"
                      > {{ item.name }} </span></div>
                    </div>
                  </td>
                  <td
                      aria-colindex="4"
                      role="cell"
                      class=""
                  >
                    {{ convertCurrency(item.price) }}
                  </td>
                </tr>
                </tbody><!----></table>
            </div>
            <div data-v-5cb60a6f="" class="mx-2 mb-2">
            </div><!----><!----></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex/dist/vuex.esm.browser'

export default {
  name: 'ProductListAttribute',
  components: {},
  data() {
    return {}
  },
  created() {
    this.$store.commit('SHOW_LOADING', true)
    this.getListAttributeByProduct(this.$route.params.id).then(res => {
      console.log('RES PRODUCT ATTRIBUTE', res)
      this.$store.commit('SHOW_LOADING', false)
    }).catch(e=>{
      this.$store.commit('SHOW_LOADING', false)
    })
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters(['getterListProduct', 'getterListAttribute']),
  },
  methods: {
    ...mapActions(['getListAttributeByProduct']),
    convertCurrency(x){
      return x.toLocaleString('vi-VN') + ' VNĐ'
    },
  },
}
</script>

<style scoped>

</style>
